@import "~@/erp/styles/variables/variables.scss";

















































































































































.print {
  background: white;
  min-height: 100vh;
  width: 100%;
  margin-top: -20px;
  padding-top: 20px;

  h1 {
    font-size: 24px;
    text-align: center;
    line-height: 50px;
  }

  .box {
    width: 720px;
    margin: 0 auto 20px;
    page-break-after: auto;
    page-break-inside: avoid;
    -webkit-print-color-adjust: exact;
    font-size: 13px;

    .img {
      width: 330px;
      height: 330px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .container {
      width: 365px;
      border: 1px solid #afafaf;
      border-radius: 2px;
      margin-left: 12px;

      ul {
        margin-top: -1px;

        li {
          list-style: none;
          line-height: 29px;
          display: flex;
          border-top: 1px solid #afafaf;

          em {
            // background: #f6f8f9;
            width: 75px;
            text-indent: 5px;
            color: #666;
            border-right: 1px solid #afafaf;
          }

          span {
            width: 285px;
            padding: 2px 5px;
            line-height: 24px;
            color: #555;
            word-break: break-all;
          }
        }
      }
    }
  }
}
