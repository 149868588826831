@import "~@/erp/styles/variables/variables.scss";









































.print-wrapper {
  min-height: 100vh;
  padding: 9px;
  overflow: auto;
  background: #fff;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s,
    color 0.1s, font-size 0s;

  font-family: 'microsoft yahei';
  color: #000000;
}
